.lia-container,
.lia-container-content {
  position: relative;
}

.lia-container-content {
  z-index: 1;
}

.lia-container-bg {
  position: absolute;
  inset: 0;
}
